'use client';

import dynamic from 'next/dynamic';

const FbLoginForBusinessBtn = dynamic(
  () => import('./fb-login-for-business-btn')
);

export default function AccountSection() {
  return <FbLoginForBusinessBtn className="w-full md:w-auto" />;
}
