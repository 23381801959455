'use client';

import { AhafoodLogo } from '@ahamove/icons/order';
import { assetPrefix } from '@ahm/api-wrappers-core';
import Image from 'next/image';
import Link from 'next/link';

export default function CustomerSection() {
  const customerItems = [
    {
      name: 'SHUYI Đà Nẵng',
      urlImage: `${assetPrefix}/static/images/shuyi.webp`,
      url: 'https://web.facebook.com/shuyidanang',
    },
    {
      name: 'Khải Đăng - Bánh Cuốn Tây Sơn Bình Định Tại Đà Nẵng',
      urlImage: `${assetPrefix}/static/images/banh-cuon-khai-dang.webp`,
      url: 'https://web.facebook.com/KhaiDang.43',
    },
    {
      name: 'Tiệm Bánh An Lê',
      urlImage: `${assetPrefix}/static/images/anle-bakery.webp`,
      url: 'https://www.facebook.com/tiembanhanle',
    },
    {
      name: 'Duyên - Cơm Gà Xối Mỡ',
      urlImage: `${assetPrefix}/static/images/duyen-com-ga.webp`,
      url: 'https://web.facebook.com/profile.php?id=100089721265510',
    },
    {
      name: 'CƠM THỐ THIÊN PHÚC',
      urlImage: `${assetPrefix}/static/images/com-thien-phuc.webp`,
      url: 'https://www.facebook.com/profile.php?id=100064035699510',
    },
    {
      name: 'Trà sữa XingFu Cha',
      urlImage: `${assetPrefix}/static/images/xingfucha.webp`,
      url: 'https://www.facebook.com/trasuaxingfucha',
    },
    {
      name: 'CƠM CHIÊN 7/3',
      urlImage: `${assetPrefix}/static/images/com-chien-73.webp`,
      url: 'https://www.facebook.com/profile.php?id=61552380807954',
    },
    {
      name: 'Cốk Cốk Tea & CaFe',
      urlImage: `${assetPrefix}/static/images/cokcok.webp`,
      url: 'https://www.facebook.com/profile.php?id=61562536006015',
    },
    {
      name: 'Xingwang cha - trà sữa đài loan',
      urlImage: `${assetPrefix}/static/images/xingwangcha.webp`,
      url: 'https://www.facebook.com/profile.php?id=100028131355672',
    },
    {
      name: 'Bánh Mì Yến',
      urlImage: `${assetPrefix}/static/images/banh-mi-yen.webp`,
      url: 'https://www.facebook.com/banhmiyen.danang',
    },
    {
      name: 'Bee Tea & Coffee',
      urlImage: `${assetPrefix}/static/images/bee.webp`,
      url: 'https://www.facebook.com/beemilkteacoffee',
    },
    {
      name: 'THÌN - Bánh Mì Heo Nướng Lu & Cà Phê Muối',
      urlImage: `${assetPrefix}/static/images/banh-mi-thin.webp`,
      url: 'https://www.facebook.com/profile.php?id=100077511534066',
    },
  ];

  return (
    <section className="bg-white pb-36 pt-96 md:pb-44 md:pt-32">
      <div className="mx-auto grid max-w-screen-xl grid-cols-1 gap-10 px-6 md:grid-cols-2 md:gap-40">
        <div className="col-span-1 my-auto space-y-6">
          <div className="space-y-1 md:space-y-4">
            <h2 className="text-2xl font-bold text-secondary-60 md:text-[64px] md:leading-[72px]">
              Khách hàng của
            </h2>
            <AhafoodLogo className="h-16 w-[259px] md:h-20 md:w-[324px]" />
          </div>
          <div className="text-base font-medium md:text-xl">
            <h3>
              Hãy khám phá ngay và trải nghiệm sự khác biệt từ AhaFood.AI mang
              lại cho việc kinh doanh của bạn!
            </h3>
            <dl>
              <dt>👉 Cài Đặt Chatbot Miễn Phí</dt>
              <dd>Bạn không tốn phí cài đặt, dễ dàng triển khai ngay!</dd>

              <dt>👉 Tăng Trưởng Doanh Thu</dt>
              <dd>
                Giúp bạn mở rộng kênh bán hàng qua Fanpage, doanh thu bứt phá
              </dd>
              <dd>Không bỏ lỡ tin nhắn đặt hàng</dd>

              <dt>👉 Tiết Kiệm Chi Phí</dt>
              <dd>Giảm chi phí trực fanpage, tăng hiệu quả kinh doanh.</dd>

              <dt>👉 Ship Thần Tốc</dt>
              <dd>Đội ngũ shipper chuyên nghiệp, giao hàng nhanh chóng.</dd>
            </dl>
          </div>
          <div className="block py-2 md:py-4">
            <a
              href="https://www.ahafood.ai/store"
              rel="noreferrer"
              className="inline-flex h-14 w-full items-center justify-center whitespace-nowrap rounded bg-primary-50 px-14 py-4 text-lg font-bold text-white ring-offset-background transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
            >
              Đặt món ngay
            </a>
          </div>
        </div>
        <div className="col-span-1">
          <div className="grid grid-cols-3 gap-10">
            <div className="flex flex-col gap-4 md:pt-20">
              {customerItems.slice(0, 4).map((items, index) => (
                <Link href={items.url} passHref legacyBehavior key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group relative block aspect-square w-full overflow-hidden rounded-full shadow hover:shadow-8"
                  >
                    <Image
                      src={items.urlImage}
                      alt={items.name}
                      fill
                      className="rounded-full object-cover transition duration-200 ease-out group-hover:scale-105"
                      priority={index < 2}
                    />
                  </a>
                </Link>
              ))}
            </div>
            <div className="flex flex-col gap-4">
              {customerItems.slice(4, 8).map((items, index) => (
                <Link href={items.url} passHref legacyBehavior key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group relative block aspect-square w-full overflow-hidden rounded-full shadow hover:shadow-8"
                  >
                    <Image
                      src={items.urlImage}
                      alt={items.name}
                      fill
                      className="rounded-full object-cover transition duration-200 ease-out group-hover:scale-105"
                      priority={index < 2}
                    />
                  </a>
                </Link>
              ))}
            </div>
            <div className="flex flex-col gap-4 md:pt-48">
              {customerItems.slice(8, 12).map((items, index) => (
                <Link href={items.url} passHref legacyBehavior key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group relative block aspect-square w-full overflow-hidden rounded-full shadow hover:shadow-8"
                  >
                    <Image
                      src={items.urlImage}
                      alt={items.name}
                      fill
                      className="rounded-full object-cover transition duration-200 ease-out group-hover:scale-105"
                      priority={index < 2}
                    />
                  </a>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
