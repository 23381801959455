'use client';

import { assetPrefix } from '@ahm/api-wrappers-core';
import { Heart, Star } from 'lucide-react';
import Image from 'next/image';
import AccountSection from '@/components/account-section';
import { Link } from '@/i18n/routing';

export default function HeroSection() {
  return (
    <section className="-mb-72 bg-secondary-90 md:-mb-8">
      <div className="mx-auto grid h-auto max-w-screen-xl grid-cols-1 space-y-10 px-6 md:mt-0 md:grid-cols-2 md:space-y-0 md:pt-0">
        <div className="relative aspect-[2050/2653] h-auto w-full bg-center md:order-2">
          <Image
            src={`${assetPrefix}/static/images/ahafood-ai-bot.webp`}
            alt="Background image"
            fill
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </div>
        <div className="space-y-6 md:order-1 md:items-center md:space-y-10 md:pt-24">
          <h2 className="text-[38px] font-bold leading-[46px] text-white md:text-[64px] md:leading-[80px]">
            <span className="text-[46px] leading-[54px] text-primary-60 md:text-[80px] md:leading-[80px]">
              Chatbot
            </span>
            <br />
            chốt món từ
            <br />
            Fanpage <span className="whitespace-nowrap">Nhà Hàng</span>
          </h2>
          <div className="text-base font-semibold text-white md:text-xl">
            <Link
              href="/"
              className="font-bold text-primary-60 hover:underline"
            >
              AhaFood.AI
            </Link>{' '}
            là sản phẩm trí tuệ nhân tạo được phát triển bởi đội ngũ{' '}
            <a
              href="https://www.ahamove.com"
              target="_blank"
              className="font-bold text-primary-60 hover:underline"
              rel="noreferrer"
            >
              Ahamove
            </a>
            , nhằm tối ưu hóa trải nghiệm mua sắm và bán hàng thông qua các cuộc
            trò chuyện thông minh. <br />
            <br />
            AhaFood.AI hứa hẹn sẽ là trợ lý thông minh, đáng tin cậy, giúp người
            bán hàng và người mua hàng tận hưởng trải nghiệm mua sắm trực tuyến
            dễ dàng và tiện lợi hơn bao giờ hết.
          </div>
          <AccountSection />
          <div className="grid gap-4 px-4 text-sm md:-mr-40 md:grid-cols-2 md:gap-6 md:px-0">
            <div className="space-y-2 rounded-3xl bg-white px-6 py-4 text-left shadow-8">
              <Heart className="size-8 fill-[#3212B3] stroke-[#3212B3]" />
              <h3 className="text-xl font-bold uppercase">
                Với cửa hàng/nhà hàng
              </h3>
              <p className="text-sm">
                AhaFood.AI là trợ lý đắc lực của các chủ nhà hàng/cửa hàng giúp
                linh hoạt quản lý và xử lý đơn hàng thông qua Fanpage Facebook.
                Đồng thời, đơn hàng cũng sẽ nhanh chóng được giao bởi đội ngũ
                tài xế chuyên nghiệp của Ahamove đến tận tay khách hàng.
              </p>
            </div>
            <div className="space-y-2 rounded-3xl bg-white px-6 py-4 text-left shadow-8">
              <Star className="size-8 fill-[#3212B3] stroke-[#3212B3]" />
              <h3 className="text-xl font-bold uppercase">
                Với người mua hàng
              </h3>
              <p className="text-sm">
                AhaFood.AI là trợ lý giúp người mua hàng dễ dàng đặt hàng qua
                tin tin nhắn siêu nhanh chỉ từ 30 giây. Người mua cũng sẽ được
                hưởng các ưu đãi ngập tràn như giảm giá món ăn, hỗ trợ ship thần
                tốc do chính người bán hàng và Ahamove hỗ trợ.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
